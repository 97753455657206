import React, { useState, useEffect } from "react";
import baseUrl from "./APIs/BaseURL";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const EditAdmin = () => {
    let navigate = useNavigate();
    // let token = localStorage.getItem("token");
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWRtaW4iLCJhZG1pbl90eXBlIjowLCJpZCI6MiwiaWF0IjoxNjQzMjY1Mzg4fQ.DOWxeIL5HgsV5MRqwgSJV5KjqfkcBoeJeW-TM1kjDVI";
    const params = useParams();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [permissions, setPermissions] = useState([]);
    const [isSuperuser, setIsSuperuser] = useState(false);
    // const [admin, setAdmin] = useState([]);
    // const [adminType, setAdminType] = useState(0);
    
    useEffect(() => {
        if (params.id) fetchAdmin(params.id);
    }, []);
    

    const fetchAdmin = async (id) => {
        console.log('Running "fetchAdmin()"');
        let parameters = {  id: +id, };

        try {
            await fetch(baseUrl + `/api/getAdminUser/${id}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                // console.log("admin data: ", data);
                // console.log("admin data[0]: ", data[0]);
                // console.log("admin data.name: ", data.name);
                // console.log("admin data[0].name: ", data[0].name);

                if (data[0].name) setName(data[0].name);
                if (data[0].email) setEmail(data[0].email);
                if (data[0].password) setPassword(data[0].password);
                if (data[0].permissions) setPermissions(data[0].permissions.split(","));
                if (data[0].isSuperuser) setIsSuperuser(data[0].isSuperuser);
            });
        } 
        catch (err) {
            console.error(err);
        }
    };

    const editAdmin = async (id) => {
        console.log('Running "editAdmin(' + id + ')"');
        let data = {
          id: +params.id,
          name,
          email,
          password,
          permissions: permissions.toString(),
          is_superuser: isSuperuser
        };
        console.log(data);
        console.log("data.id: ", data.id);
    
        try {
          await fetch(baseUrl + `/api/updateAdminUser/${data.id}`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              token: token,
            },
          })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log("data: ", data);
            if (data === true) {
              alert("Admin updated successfully.");
              navigate("/administrators");
            } 
            else {
              console.log("else block data: ", data);
              alert("Something went wrong");
            }
          });
        } 
        catch (err) {
          console.error(err);
        }
    };

    return (
        <div>
          <div>
            <div className="container mt-3">
              <div className="card col-md-12">
                <div className="card-body">
                  <h2>Edit Admin</h2>
    
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <label>Name</label>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="name"
                            value={name}
                            onChange={(ev) => {
                              setName(ev.target.value);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Email</label>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="email"
                            value={email}
                            onChange={(ev) => {
                              setEmail(ev.target.value);
                            }}
                          />
                        </td>
                      </tr>
                      {/* <tr
                        style={
                          adminType == 0 ? { display: "" } : { display: "none" }
                        }
                      >
                        <td>
                          <label>Password</label>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="password"
                            value={password}
                            onChange={(ev) => {
                              setPassword(ev.target.value);
                            }}
                          />
                        </td>
                      </tr> */}
                      {/* <tr
                        style={
                          adminType == 1 ? { display: "" } : { display: "none" }
                        }
                      >
                        <td>
                          <label>Hospital</label>
                        </td>
                        <td>
                          <select
                            className="form-control"
                            value={hospitalId}
                            onChange={(ev) => {
                              setHospitalId(ev.target.value);
                            }}
                          >
                            {hospitals.map((item, ind) => (
                              <option key={ind} value={ind}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr> */}
                      <tr>
                        <td>
                          <label>Permissions</label>
                        </td>
                        <td>
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td><input type="checkbox" checked={ permissions.indexOf("hospital_credentials") >= 0 ? true : false } onChange={ (ev) => { 
                                            console.log(ev.target.checked); 
                                            if(ev.target.checked == true) setPermissions((prevState) => { return [...prevState,"hospital_credentials"]  })
                                            if(ev.target.checked == false) setPermissions((prevState) => { return prevState.filter((item) => { return item != "hospital_credentials" }); })
                                        } }></input>&nbsp;<p style={{display: "inline-block"}}>hospital credentials</p></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" checked={ permissions.indexOf("hospitals") >= 0 ? true : false } onChange={ (ev) => { 
                                            console.log(ev.target.checked); 
                                            if(ev.target.checked == true) setPermissions((prevState) => { return [...prevState,"hospitals"]  })
                                            if(ev.target.checked == false) setPermissions((prevState) => { return prevState.filter((item) => { return item != "hospitals" }); })
                                        } }></input>&nbsp;<p style={{display: "inline-block"}}>hospitals</p></td>
                                        
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" checked={ permissions.indexOf("external_pharmacies") >= 0 ? true : false } onChange={ (ev) => { 
                                            console.log(ev.target.checked); 
                                            if(ev.target.checked == true) setPermissions((prevState) => { return [...prevState,"external_pharmacies"]  })
                                            if(ev.target.checked == false) setPermissions((prevState) => { return prevState.filter((item) => { return item != "external_pharmacies" }); })
                                        } }></input>&nbsp;<p style={{display: "inline-block"}}>external pharmacies</p></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" checked={ permissions.indexOf("doctors") >= 0 ? true : false } onChange={ (ev) => { 
                                            console.log(ev.target.checked); 
                                            if(ev.target.checked == true) setPermissions((prevState) => { return [...prevState,"doctors"]  })
                                            if(ev.target.checked == false) setPermissions((prevState) => { return prevState.filter((item) => { return item != "doctors" }); })
                                        } }></input>&nbsp;<p style={{display: "inline-block"}}>doctors</p></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" checked={ permissions.indexOf("patients") >= 0 ? true : false } onChange={ (ev) => { 
                                            console.log(ev.target.checked); 
                                            if(ev.target.checked == true) setPermissions((prevState) => { return [...prevState,"patients"]  })
                                            if(ev.target.checked == false) setPermissions((prevState) => { return prevState.filter((item) => { return item != "patients" }); })
                                        } }></input>&nbsp;<p style={{display: "inline-block"}}>patients</p></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" checked={ permissions.indexOf("prescriptions") >= 0 ? true : false } onChange={ (ev) => { 
                                            console.log(ev.target.checked); 
                                            if(ev.target.checked == true) setPermissions((prevState) => { return [...prevState,"prescriptions"]  })
                                            if(ev.target.checked == false) setPermissions((prevState) => { return prevState.filter((item) => { return item != "prescriptions" }); })
                                        } }></input>&nbsp;<p style={{display: "inline-block"}}>prescriptions</p></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" checked={ permissions.indexOf("diseases") >= 0 ? true : false } onChange={ (ev) => { 
                                            console.log(ev.target.checked); 
                                            if(ev.target.checked == true) setPermissions((prevState) => { return [...prevState,"diseases"]  })
                                            if(ev.target.checked == false) setPermissions((prevState) => { return prevState.filter((item) => { return item != "diseases" }); })
                                        } }></input>&nbsp;<p style={{display: "inline-block"}}>diseases</p></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" checked={ permissions.indexOf("drugs") >= 0 ? true : false } onChange={ (ev) => { 
                                            console.log(ev.target.checked); 
                                            if(ev.target.checked == true) setPermissions((prevState) => { return [...prevState,"drugs"]  })
                                            if(ev.target.checked == false) setPermissions((prevState) => { return prevState.filter((item) => { return item != "drugs" }); })
                                        } }></input>&nbsp;<p style={{display: "inline-block"}}>drugs</p></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" checked={ permissions.indexOf("pharmacy_managers") >= 0 ? true : false } onChange={ (ev) => { 
                                            console.log(ev.target.checked); 
                                            if(ev.target.checked == true) setPermissions((prevState) => { return [...prevState,"pharmacy_managers"]  })
                                            if(ev.target.checked == false) setPermissions((prevState) => { return prevState.filter((item) => { return item != "pharmacy_managers" }); })
                                        } }></input>&nbsp;<p style={{display: "inline-block"}}>pharmacy manager</p></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" checked={ permissions.indexOf("inventory_admins") >= 0 ? true : false } onChange={ (ev) => { 
                                            console.log(ev.target.checked); 
                                            if(ev.target.checked == true) setPermissions((prevState) => { return [...prevState,"inventory_admins"]  })
                                            if(ev.target.checked == false) setPermissions((prevState) => { return prevState.filter((item) => { return item != "inventory_admins" }); })
                                        } }></input>&nbsp;<p style={{display: "inline-block"}}>inventory admin</p></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" checked={ permissions.indexOf("RxDrugs") >= 0 ? true : false } onChange={ (ev) => { 
                                            console.log(ev.target.checked); 
                                            if(ev.target.checked == true) setPermissions((prevState) => { return [...prevState,"RxDrugs"]  })
                                            if(ev.target.checked == false) setPermissions((prevState) => { return prevState.filter((item) => { return item != "RxDrugs" }); })
                                        } }></input>&nbsp;<p style={{display: "inline-block"}}>RxDrugs</p></td>
                                    </tr>
                                </tbody>
                            </table>
                          </td>
                        </tr>
                      <tr>
                        <td>
                          <label>Superadmin</label>
                        </td>
                        <td>
                          <input type="checkbox" 
                          checked={ isSuperuser == true ? true : false }
                          onChange={(ev) => {
                            if(ev.target.checked) {
                              setIsSuperuser(true);
                            }
                            if(ev.target.checked == false) {
                              setIsSuperuser(false);
                            }
                          }}
                        />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <button className="btn btn-primary" onClick={editAdmin}>
                            Update
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default EditAdmin;
