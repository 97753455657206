import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import pharmacyRxBaseUrl from "./APIs/BaseURL";

const EditDrug = () => {
    let navigate = useNavigate();
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWRtaW4iLCJhZG1pbl90eXBlIjowLCJpZCI6MiwiaWF0IjoxNjQzMjY1Mzg4fQ.DOWxeIL5HgsV5MRqwgSJV5KjqfkcBoeJeW-TM1kjDVI";
    const params = useParams();

    const [drugCode, setDrugCode] = useState("");
    const [drugName, setDrugName] = useState("");
    const [semanticBrandName, setSemanticBrandName] = useState("");

    useEffect(() => {
        if(params.id) fetchDrug(params.id);
    }, [])

    const fetchDrug = async (id) => {
        console.log('Running "fetchDrug(' + id + ')"');
        let parameters = {
            id: +id,
        };

        try {
            await fetch(pharmacyRxBaseUrl + "/api/getdrug/", {
                method: "POST",
                body: JSON.stringify(parameters),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("fetchHospital(" + id + ")", data);
                if (data.drug_code) setDrugCode(data.drug_code);
                if (data.drug_name) setDrugName(data.drug_name);
                if (data.semantic_brand_name) setSemanticBrandName(data.semantic_brand_name);
            });
        } 
        catch (err) {
            console.error(err);
        }
    }

    const editCurrentDrug = async () => {
        console.log('Running "editCurrentDrug()"');
        let data = {
            id: +params.id,
            drug_code: drugCode,
            drug_name: drugName,
            semantic_brand_name: semanticBrandName
        }
        console.log("data from editCurrentDrug() after instantiation: ", data);

        try {
            await fetch(pharmacyRxBaseUrl + "/api/updatedrug", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("data from editCurrentDrug() try block: ", data);
                if (data.status == "success") {
                    alert(data.message);
                    navigate("/drugs");
                }
                else alert(data.message ? data.message : "Something went wrong");
            })
        } 
        catch (err) {
            console.error(err);
        }
    }

    return (
        <div>
            <div className="container mt-3">
                <div className="card col-md-12">
                    <div className="card-body">
                        <h2>Edit Drug</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Drug Code</label> {/* DRUG CODE INPUT */}
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="name"
                                            value={drugCode}
                                            onChange={(ev) => {
                                                setDrugCode(ev.target.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Drug Name</label> {/* DRUG NAME INPUT */}
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="name"
                                            value={drugName}
                                            onChange={(ev) => {
                                                setDrugName(ev.target.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Semantic Brand Name</label> {/* SBN INPUT */}
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="name"
                                            value={semanticBrandName}
                                            onChange={(ev) => {
                                                setSemanticBrandName(ev.target.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                               
                                <tr>
                                    <td colSpan={2}>
                                        <button className="btn btn-primary" onClick={editCurrentDrug}>
                                            Update {/* ADD BUTTON */}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditDrug;