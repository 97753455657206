import React, { useState, useEffect } from "react";
import pharmacyRxBaseUrl from "./APIs/BaseURL";

const Disease = () => {
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWRtaW4iLCJhZG1pbl90eXBlIjowLCJpZCI6MiwiaWF0IjoxNjQzMjY1Mzg4fQ.DOWxeIL5HgsV5MRqwgSJV5KjqfkcBoeJeW-TM1kjDVI";

    const [diseases, setDiseases] = useState([]);
    const [name, setName] = useState("");
    const [semanticBrandName, setSemanticBrandName] = useState("");

    useEffect(() => {
        fetchDiseases();
    }, []);

    const fetchDiseases = async () => {
        let data = {};
        if (name) data["name"] = name;
        if (semanticBrandName) data["semantic_brand_name"] = semanticBrandName;
        console.log("fetchDiseases() data{} pre fetch", data);

        try {
            await fetch(pharmacyRxBaseUrl + "/api/getdiseases", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  token: token,
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("fetchDiseases() data in try block: ", data);
                setDiseases(data);
            });
        } 
        catch (err) {
            console.error(err);
        }
    }

    return (
        <div>
            <div className="row">
                <div className="card col-md-12">
                    <div className="card-body">
                        <form>
                            <h1>Diseases</h1>
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Disease Name</th>
                                        <th>Semantic Branded Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { diseases.map((disease, id) => (
                                        <tr key={id}>
                                            <td>{disease.name}</td>
                                            <td>{disease.semantic_brand_name}</td>
                                            <td>*Action buttons*</td>
                                        </tr>
                                    )) }
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Disease;