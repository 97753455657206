import React, { Component, useState, useEffect } from 'react';
import { CSVLink } from "react-csv";

const headers = [
    {label: 'First Name', key: 'firstName'},
    {label: 'Last Name', key: 'lastName'},
    {label: 'Email', key: 'email'}
];

const Loader = () => {

    useEffect(() => {
        fetchBrands();
      }, []);

    const [semanticBrands, setSemanticBrands] = useState([]);
    const [csvData, setCsvData] = useState([]);

    const conceptHeaders = [
        {label: 'RXCUI', key: 'rxcui'},
        {label: 'Name', key: 'name'},
        {label: 'Synonym', key: 'synonym'},
        {label: 'TTY', key: 'tty'},
        {label: 'Language', key: 'language'},
        {label: 'Suppress', key: 'suppress'},
        {label: 'UMLSCUI', key: 'umlscui'}
    ];
    
    const [csvReport, setCsvReport] = useState({
        filename: 'Report.csv',
        headers: conceptHeaders,
        data: semanticBrands
    });    


    const fetchConcepts = () => {
        console.log("Running fetchConcepts()")

        fetch("https://rxnav.nlm.nih.gov/REST/termtypes.json").then((response) => {
            return response.json();
        })
        .then((ttyJson) => {
            const reqBody = {
                ttyJson,
            };
            const formdata = new FormData();
            formdata.append("ttyJson", ttyJson);
            var myHeader = new Headers();
            myHeader.append("Content-Type", "application/json");
            fetch("/api/termTypes", {
                method: "POST",
                headers: myHeader,
                body: JSON.stringify(reqBody),
            })
            .then(function() {
                fetch("/api").then((response) => {
                    return response.json();
                })
                .then((searchConceptsJson) => {
                    const reqBody = {
                        searchConceptsJson,
                    };
                    const formdata = new FormData();
                    formdata.append("searchConceptsJson", searchConceptsJson);
                    var myHeader = new Headers();
                    myHeader.append("Content-Type", "application/json");
                    fetch("api/conceptProperties", {
                        method: "POST",
                        headers: myHeader,
                        body: JSON.stringify(reqBody),
                    })

                })
            })
            .then(function() {
                fetch("/api").then((response) => {
                    return response.json();
                })
                .then((searchConceptsJson) => {
                    const reqBody = {
                        searchConceptsJson,
                    };
                    const formdata = new FormData();
                    formdata.append("searchConceptsJson", searchConceptsJson);
                    var myHeader = new Headers();
                    myHeader.append("Content-Type", "application/json");
                    fetch("/api/allProperties", {
                        method: "POST",
                        headers: myHeader,
                        body: JSON.stringify(reqBody),
                    })
                    .then((data) => {
                        document.getElementById('loadingIcon').style.display = "none";
                    })
                })

            });
       

        })
    }

    const fetchBrands = () => {
        console.log('Running "fetchBrands()"');
    
        fetch("https://rxnav.nlm.nih.gov/REST/allconcepts.json?tty=sbd")
            .then((response) => {
                console.log("fetchBrands() response " + response);
                return response.json();
            })
            .then((data) => {
                console.log("fetchBrands() data.minConceptGroup.minConcept: ", data.minConceptGroup.minConcept);
                if (data.minConceptGroup.minConcept) setSemanticBrands(data.minConceptGroup.minConcept);
                console.log("fetchBrands() data.result: ", data.result);
                setCsvData(data.minConceptGroup.minConcept); 
            })
            .catch((err) => {
                console.log("error occured ", err);
            });
    };


    return (
        <div>
            <p>Refresh the database</p>
        <div id="refresh">

        <h1>POPULATE DATABASE</h1>
        <h5>Run query to refresh rxnorm concepts.</h5>
        {/* <div>
            <img src="loading.gif" id="loadingIcon"  style="display: none;margin: 20px;width: 80px;">
        </div> */}

        <button onClick={
            (ev) => {
                fetchConcepts();
            }} id="button" type="button">Reload Database</button>

        </div >
            <CSVLink headers={conceptHeaders} data={csvData}>Export to CSV</CSVLink>
        </div>
    );
}

export default Loader;