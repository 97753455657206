import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import baseUrl from "./APIs/BaseURL";

const HeaderComponent = () => {
  let navigate = useNavigate();
  console.log("path name: ", window.location.pathname);
  let [permissions, setPermissions] = useState("");
  let [isSuperuser, setIsSuperuser] = useState(false);

  useEffect(() => {
    fetchPermissions();
    console.log("permissions: *", permissions, "* <<< PERMISSIONS CON CLUDED!");
  }, []);

  const fetchPermissions = async () => {
    console.log('Running "fetchPermissions()"');

    try {
      await fetch(baseUrl + "/api/getpermissions", {
        method: "POST",
        body: JSON.stringify({
          email: localStorage.email
      }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // token: token, // DOUBLE CHECK
        },
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(localStorage.email);
        console.log("fetchPermissions() data[0].permissions: ", data[0].permissions);
        console.log("fetchPermissions() data[0].is_superuser: ", data[0].is_superuser);

        if (data[0].permissions) setPermissions(data[0].permissions);
        if (data[0].is_superuser) setIsSuperuser(data[0].is_superuser);
      
        // console.log("fetchPermissions() data[0].is_superuser: ", data[0].is_superuser);
      });
    } 
    catch (err) {
      console.error(err);
    }
  }




  // const allDivsArray = [
  //   <AdminDiv />,
  //   <CredentialsDiv />,
  // ];

  const AllDivs = async () => {
    fetchPermissions();
    return (
      <ul></ul>
      // <ul>{allDivsArray}</ul>
      
    );
    
  }

  let AdminDiv = () => {
    fetchPermissions();
    return(
      
      permissions.includes("admin") ? 
      <li className="header-dropdown-item">
        <a href="/administrators" className="header-dropdown-link">Admin</a>
      </li> : <div></div>
    );
  }

  const CredentialsDiv = () => {
    return(
      permissions.includes("credentials") ? 
      <li className="header-dropdown-item">
        <a href="/credentials" className="header-dropdown-link">Credentials</a>
      </li> : <div></div>
    );
  }

  const DoctorsDiv = () => {
    return(
      permissions.includes("doctor") ? 
      <li className="header-dropdown-item">
        <a href="/doctors" className="header-dropdown-link">*Doctor*</a>
      </li> : <div></div>
    );
  }

  // admindiv
  // credentialsdiv
  // hospitaldiv
  // doctordiv
  // patietn divprescriptionsdiv
  // drugsdiv
  // diseasediv

  return(
    window.location.pathname.length > 1 ? 
    <div>
          <header>
              <nav className="navbar navbar-expand-md navbar-dark bg-dark">
                  {/* <h1>ASCEND</h1> */}
                  {/* <div><a href="https://ascend.ascendehr.com/landing-page" className="navbar-brand">JajiRx Admin Portal</a></div> */}
                  <div><a href="/home" className="navbar-brand">JajiRx Admin Portal</a></div>
                  <div><h1  className="red">Logged in as</h1></div>
                  <ul className="header-list">
                      {/* <li className="header-item">
                          <a href="header-link" className="navbar-brand">HOME</a>
                      </li> */}
                              
                      <li className="header-item">
                        <a href="header-link" className="navbar-brand">Menu</a>
                        <ul className="header-dropdown">
                          {
                            isSuperuser == true ? 
                            <li className="header-dropdown-item">
                              <a href="/administrators" className="header-dropdown-link">Admin</a>
                            </li> : <></>
                          }
                          {
                            permissions.indexOf("hospitals") >= 0 ? 
                            <li className="header-dropdown-item">
                              <a href="/hospitals" className="header-dropdown-link">Hospital</a>
                            </li> : <></>
                          }
                          {
                            permissions.indexOf("doctors") >= 0 ? 
                            <li className="header-dropdown-item">
                              <a href="/doctors" className="header-dropdown-link">Doctor</a>
                            </li> : <></>
                          }
                          {
                            permissions.indexOf("patients") >= 0 ? 
                            <li className="header-dropdown-item">
                              <a href="/patients" className="header-dropdown-link">Patient</a>
                            </li> : <></>
                          }
                          {
                            permissions.indexOf("prescriptions") >= 0 ? 
                            <li className="header-dropdown-item">
                              <a href="/prescriptions" className="header-dropdown-link">Prescriptions</a>
                            </li> : <></>
                          }
                          {
                            permissions.indexOf("disease") >= 0 ? 
                            <li className="header-dropdown-item">
                              <a href="/diseases" className="header-dropdown-link">Disease</a>
                            </li> : <></>
                          }
                          {
                            permissions.indexOf("drugs") >= 0 ? 
                            <li className="header-dropdown-item">
                              <a href="/drugs" className="header-dropdown-link">Drug</a>
                            </li> : <></>
                          }
                          {
                            permissions.indexOf("pharmacy_managers") >= 0 ? 
                            <li className="header-dropdown-item">
                              <a href="/pharmacy" className="header-dropdown-link">Pharmacy</a>
                            </li> : <></>
                          } 
                          {
                            permissions.indexOf("inventory_admins") >= 0 ? 
                            <li className="header-dropdown-item">
                              <a href="/inventory" className="header-dropdown-link">Inventory</a>
                            </li> : <></>
                          }
                          {
                            permissions.indexOf("RxDrugs") >= 0 ? 
                            <li className="header-dropdown-item">
                              <a href="/jajirx" className="header-dropdown-link">RxDrugs</a>
                            </li> : <></>
                          }
                          <li className="header-dropdown-item">
                              <a href="/profile" className="header-dropdown-link">Pharmacy Profile</a>
                          </li>
                          {/* <li className="header-dropdown-item">
                              <a href="/inventory" className="header-dropdown-link">Inventory</a>
                          </li>
                          <li className="header-dropdown-item">
                              <a href="/jajirx" className="header-dropdown-link">Drug Search</a>
                          </li> */}
                          {/* <li className="header-dropdown-item">
                              <a href="#" className="header-dropdown-link">Inventory Admin</a>
                          </li>
                          <li className="header-dropdown-item">
                              <a href="#" className="header-dropdown-link">Pharmacy Manager</a>
                          </li> */}
                        </ul>
                      </li>
                  </ul>
              </nav>
          </header>







  {/* CODEPEN html BEGINNING */}

  {/* <header class="header">
    <div class="container"> ----
      <div class="header__container"> ----
        <a href="#" class="header__logo logo">Logo</a>
        <nav class="header__nav nav"> ----
          <ul class="header__list"> ----
            <li class="header__item"> ----
              <a class="header__link" href="#">Home</a> ----
            </li>
            <li class="header__item">
              <a class="header__link" href="#">About</a>
              <ul class="header__dropdown">
                <li class="header__dropdown-item">
                  <a href="#" class="header__dropdown-link">Hello</a>
                </li>
                <li class="header__dropdown-item">
                  <a href="#" class="header__dropdown-link">Welcome</a>
                </li>
                <li class="header__dropdown-item">
                  <a href="#" class="header__dropdown-link">Features</a>
                </li>
                <li class="header__dropdown-item">
                  <a href="#" class="header__dropdown-link">Info</a>
                </li>
              </ul>
            </li>
            <li class="header__item">
              <a class="header__link" href="#">Features</a>
              <ul class="header__dropdown">
                <li class="header__dropdown-item">
                  <a href="#" class="header__dropdown-link">Hello</a>
                </li>
                <li class="header__dropdown-item">
                  <a href="#" class="header__dropdown-link">Welcome</a>
                </li>
                <li class="header__dropdown-item">
                  <a href="#" class="header__dropdown-link">Features</a>
                </li>
                <li class="header__dropdown-item">
                  <a href="#" class="header__dropdown-link">Info</a>
                </li>
              </ul>
            </li>
            <li class="header__item">
              <a class="header__link" href="#">Pricing</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header> */}

  {/* CODEPEN html ENDING */}



  {/* CODEPEN css BEGINNING */}

  {/* * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
    color: #6f66c2;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  .logo {
    font-size: 2rem;
    font-weight: bold;
  }

  .container {
    max-width: 1320px;
    padding: 0 20px;
    margin: 0 auto;
  }

  .header__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 6.25rem;
  }

  .header__nav {
    display: flex;
    align-items: center;
  }

  .header__logo {
    margin-right: auto;
  }

  .header__list {
    display: flex;
    align-items: center;
  }

  .header__item {
    position: relative;
  }

  .header__item:not(:last-child) {
    margin-right: 3rem;
  }

  .header__link {
    font-size: 1.2rem;
    font-family: sans-serif;
    font-weight: 600;
    transition: color 0.1s ease;
  }

  .header__dropdown {
    visibility: hidden;
    position: absolute;
    border-radius: 10px;
    padding: 1.5rem 0;
    min-width: 11.875rem;
    background-color: #7763d7;
    left: -2rem;
    top: 2rem;
    display: flex;
    flex-direction: column;
    transition: visibility 0.1s ease;
  }

  .header__item:hover .header__dropdown {
    visibility: visible;
  }
  .header__item:hover .header__link {
    color: #4297f8;
  }
  .header__link:focus {
    color: #4297f8;
  }
  .header__link:focus + .header__dropdown {
    visibility: visible;
  }

  .header__dropdown::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -5px;
    left: 30%;
    width: 1.25rem;
    height: 1.25rem;
    transform: rotate(45deg);
    background-color: #7763d7;
  }

  .header__dropdown-link {
    color: #fff;
    font-size: 1.1rem;
    font-weight: 500;
    word-break: break-all;
  }

  .header__dropdown-item {
    display: flex;
    padding: 1rem 2rem;
    width: 100%;
    background-color: transparent;
    transition: background-color 0.1s ease;
  }

  .header__dropdown-item:hover {
    background-color: #3c299b;
  } */}


  {/* CODEPEN css ENDING */}




          </div>


  : <div></div>
  );
}

export default HeaderComponent;
