import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import baseUrl from "./APIs/BaseURL";
import pharmacyRxBaseUrl from "./APIs/BaseURL";

const Prescription = () => {
    let navigate = useNavigate();
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWRtaW4iLCJhZG1pbl90eXBlIjowLCJpZCI6MiwiaWF0IjoxNjQzMjY1Mzg4fQ.DOWxeIL5HgsV5MRqwgSJV5KjqfkcBoeJeW-TM1kjDVI";

    const [prescriptionsData, setPrescriptionsData] = useState([]);
    const [patients, setPatients] = useState([]);
    const [doctors, setDoctors] = useState([]);

    const [hospitalPatientId, setHospitalPatientId] = useState("");
    const [patientFirstName, setPatientFirstName] = useState("");
    const [patientMiddleName, setpatientMiddleName] = useState("");
    const [patientLastName, setPatientLastName] = useState("");

    const [hospitalDoctorId, setHospitalDoctorId] = useState("");
    const [doctorFirstName, setDoctorFirstName] = useState("");
    const [doctorMiddletName, setDoctorMiddletName] = useState("");
    const [doctorLastName, setDoctorLastName] = useState("");

    const [hospitalPharmacyId, setHospitalPharmacyId] = useState("");
    const [pharmacyName, setPharmacyName] = useState("");
    const [drugs, setDrugs] = useState([]);

    useEffect(() => {
        fetchPrescriptions();
        fetchPatients();
        fetchDoctors();
    }, []);

    const fetchPrescriptions = async () => {
        console.log('Running "fetchPrescriptions()"');

        try {
            await fetch(baseUrl + "/api/getprescriptions", {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  token: token,
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("fetchPrescriptions() data in try block: ", data);
                setPrescriptionsData(data);
            });
            
        } catch (err) {
            console.error(err);
        }
    };
    
    const fetchPatients = async () => {
        console.log('Running "fetchPatients()"');

        try {
            await fetch(baseUrl + "/api/getpatients", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("patients data: ", data);
                setPatients(data);
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchDoctors = async () => {
        console.log('Running "fetchDoctors()"');

        try {
            await fetch(baseUrl + "/api/getdoctors", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("dosctors data: ", data);
                setDoctors(data);
            });
        } catch (err) {
            console.error(err);
        }
    };


    // const displayPrescription = async () => {
    //     setPatientFirstName("");
    //     setpatientMiddleName("");
    //     setPatientLastName("");
    //     setPatientGender("");
    //     setPatientStreetAddress("");
    //     setPatientCity("");
    //     setPatientZipCode("");
    //     setState("");

    //     setDoctorFirstName("");
    //     setDoctorMiddletName("");
    //     setDoctorLastName("");
    //     setDoctorGender("");
    //     setDoctorStreetAddress("");
    //     setDoctorCity("");
    //     setDoctorZipCode("");
    //     setDoctorState("");

    //     setPatientFirstName("");
    //     setpatientMiddleName("");
    //     setPatientLastName("");
    //     setPatientGender("");
    //     setPatientStreetAddress("");
    //     setPatientCity("");
    //     setPatientZipCode("");
    //     setState("");

    //     setDoctorFirstName("");
    //     setDoctorMiddletName("");
    //     setDoctorLastName("");
    //     setDoctorGender("");
    //     setDoctorStreetAddress("");
    //     setDoctorCity("");
    //     setDoctorZipCode("");
    //     setDoctorState("");
    // }


    return (
        <div>
            <div>
                <div className="row">

                        <div className="card col-md-12">
                            {/* <h3 className="text-center">Add Prescription</h3> */}

    

                            <div className="card-body">

                                {/* -- FILTER -- */}
                                <h1 className="text-center">Prescriptions</h1>
                                <div className="container">
                                    <div className="row justify-content-center" id="search">
                                        <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                            <select
                                                className="form-control"
                                                value={hospitalPatientId}
                                                onChange={(ev) => {
                                                    setHospitalPatientId(ev.target.value);
                                                }}
                                            >
                                                <option value="">SELECT</option>
                                                {patients.map((item, ind) => (
                                                    <option key={ind} value={item.hospital_patient_id}>
                                                        {item.first_name + " " + item.last_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                            <select
                                                className="form-control"
                                                value={hospitalDoctorId}
                                                onChange={(ev) => {
                                                    setHospitalDoctorId(ev.target.value);
                                                }}
                                            >
                                                <option value="">SELECT</option>
                                                {doctors.map((item, ind) => (
                                                    <option key={ind} value={item.hospital_doctor_id}>
                                                        {item.first_name + " " + item.last_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {/* <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Search hospital name" // SEARCH PHARMACY NAME INPUT
                                                //   value={email}
                                                onChange={(ev) => {
                                                    // setEmail(ev.target.value);
                                                }}
                                            />
                                        </div> */}

                                        <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                  onClick={fetchPrescriptions}
                                            >
                                                Search {/* SEARCH BUTTON */}
                                            </button>
                                            &nbsp;
                                            <button
                                                type="submit"
                                                className="btn btn-secondary"
                                                onClick={() => {
                                                    navigate("/addadmin");
                                                }}
                                            >
                                                Add {/* ADD BUTTON */}
                                            </button>
                                        </div>
                                    </div>
                                </div>













                                <div className="row">
                                    <a href="/add-prescription"><button class="btn btn-primary">Add Prescription</button></a>
                                </div>
                                   
                        
                                    {/* Table */}
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Pharmacy Name</th>
                                                <th>Patient Name</th>
                                                <th>Doctor Name</th>
                                                <th>Prescription</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { prescriptionsData.map((item, ind) => (
                                                <tr key={ind}>
                                                    <td>{item.pharmacy_name}</td>
                                                    <td>{item.patient_first_name + " " + item.patient_last_name}</td>
                                                    <td>{item.doctor_first_name + " " + item.doctor_last_name}</td>
                                                    <td>
                                                        { item.drugs.map((item, ind) => (
                                                            <ul key={ind}>
                                                                <li>{item.drug_code}</li>
                                                            </ul>
                                                        )) }
                                                    </td>
                                                </tr>
                                            )) }
                                        </tbody>
                                    </table>
                                    

                            </div>
                        </div>

                </div>
            </div>
        </div>
    );
};

export default Prescription;






