import React, { useState } from "react";
import baseUrl from "../pharmacyRxComponents/APIs/BaseURL";

const Home = () => {
    const [search, setSearch] = useState("");
    const [concepts, setConcepts] = useState([]);
  
    const [bnConcepts, setBnConcepts] = useState([]);
    const [inConcepts, setInConcepts] = useState([]);
    const [minConcepts, setMinConcepts] = useState([]);
    const [bpckConcepts, setBpckConcepts] = useState([]);
    const [gpckConcepts, setGpckConcepts] = useState([]);
    const [sbdConcepts, setSbdConcepts] = useState([]);
    const [scdConcepts, setScdConcepts] = useState([]);
    const [dfgConcepts, setDfgConcepts] = useState([]);
  
    const searchData = (search_key) => {
      console.log('Running "searchData()" for ' + search_key);
  
    try {
      fetch(baseUrl + "/api/concept/" + search_key, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          email: localStorage.getItem("email"), // see if still works after refresh
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("data found", data);
          if (data) setConcepts(data);
        });
    } catch (error) {
      
    }


    
  };
      
  
    const displayBnConcepts = () => {
      console.log('Running "displayBnConcepts()"');
  
      fetch(baseUrl + "/api/bn", {
        headers: {
          Accept: "application.json",
          "Content-type": "application/json; charset=UTF-8",
          email: localStorage.getItem("email"),
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("data found", data);
          if (data) setBnConcepts(data);
        })
        .catch((err) => {
          console.log("error occured ", err);
        });
    };
  
    const displayTTY = async (ev, id) => {
      console.log(ev, id);
      const jsonRequest = {};
      jsonRequest.id = id;
      jsonRequest.textContent = ev.target.textContent;
      const rxcui = jsonRequest.id;
      const name = jsonRequest.textContent;
      console.log(
        "ln 300 jsonRequest.id (rxcui): ",
        rxcui + "\nln 302 jsonRequest.textContent (name):",
        name
      );
      setConcepts([]);
      setSearch("");
      displayBn(rxcui, name);
      displayIn(rxcui, name);
      displayMin(rxcui, name);
      displayBpck(rxcui, name);
      displayGpck(rxcui, name);
      displaySbd(rxcui, name);
      displayScd(rxcui, name);
      displayDfg(rxcui, name);
    };
  
    const displayBn = async (rxcui, name) => {
      setBnConcepts([]);
      try {
        const result = await fetch(baseUrl + "/api/bn", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            rxcuiHolder: rxcui,
            nameHolder: name,
          }),
        });
        const bnTtys = await result.json();
        console.log("bnTtys", bnTtys);
  
        if (bnTtys.result) setBnConcepts(bnTtys.result);
      } catch (err) {
        console.log(err);
      }
    };
  
    const displayIn = async (rxcui, name) => {
      setInConcepts([]);
      try {
        const result = await fetch(baseUrl + "/api/in", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            rxcuiHolder: rxcui,
            nameHolder: name,
          }),
        });
        const inTtys = await result.json();
        console.log("inTtys", inTtys);
        if (inTtys.result) setInConcepts(inTtys.result);
      } catch (err) {
        console.log(err);
      }
    };
  
    const displayMin = async (rxcui, name) => {
      setMinConcepts([]);
      try {
        const result = await fetch(baseUrl + "/api/min", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            rxcuiHolder: rxcui,
            nameHolder: name,
          }),
        });
        const minTtys = await result.json();
        console.log("minTtys", minTtys);
        if (minTtys.result) setMinConcepts(minTtys.result);
      } catch (err) {
        console.log(err);
      }
    };
  
    const displayBpck = async (rxcui, name) => {
      setBpckConcepts([]);
      try {
        const result = await fetch(baseUrl + "/api/bpck", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            rxcuiHolder: rxcui,
            nameHolder: name,
          }),
        });
        const bpckTtys = await result.json();
        console.log("bpckTtys", bpckTtys);
        if (bpckTtys.result) setBnConcepts(bpckTtys.result);
      } catch (err) {
        console.log(err);
      }
    };
  
    const displayGpck = async (rxcui, name) => {
      setGpckConcepts([]);
      try {
        const result = await fetch(baseUrl + "/api/gpck", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            rxcuiHolder: rxcui,
            nameHolder: name,
          }),
        });
        const gpckTtys = await result.json();
        console.log("gpckTtys", gpckTtys);
        if (gpckTtys.result) setGpckConcepts(gpckTtys.result);
      } catch (err) {
        console.log(err);
      }
    };
  
    const displaySbd = async (rxcui, name) => {
      setSbdConcepts([]);
      try {
        const result = await fetch(baseUrl + "/api/sbd", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            rxcuiHolder: rxcui,
            nameHolder: name,
          }),
        });
        const sbdTtys = await result.json();
        console.log("sbdTtys", sbdTtys);
        if (sbdTtys.result) setSbdConcepts(sbdTtys.result);
      } catch (err) {
        console.log(err);
      }
    };
  
    const displayScd = async (rxcui, name) => {
      setScdConcepts([]);
      console.log("existing SCD div cleared");
  
      try {
        const result = await fetch(baseUrl + "/api/scd", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            rxcuiHolder: rxcui,
            nameHolder: name,
          }),
        });
        const scdTtys = await result.json();
        console.log("scdTtys", scdTtys);
        if (scdTtys.result) setScdConcepts(scdTtys.result);
      } catch (err) {
        console.log(err);
      }
    };
  
    async function displayDfg(rxcui, name) {
      setDfgConcepts([]);
      try {
        const result = await fetch(baseUrl + "/api/dfg", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            rxcuiHolder: rxcui,
            nameHolder: name,
          }),
        });
        const dfgTtys = await result.json();
        console.log("dfgTtys", dfgTtys);
        if (dfgTtys.result) setDfgConcepts(dfgTtys.result);
      } catch (err) {
        console.log(err);
      }
    }
  
    return (
      <div>
        <div className="App jajirx-body">
          {/* <header className="App-header"></header> */}
  
          <h1 style={{font: "Courier"}}>SEARCH DATABASE</h1>
          <div class="search_row">
            <div class="search_wrap">
              <input
                id="search"
                placeholder="Search here"
                value={search}
                onChange={(ev) => {
                  console.log(ev);
                  setSearch(ev.target.value);
                  searchData(ev.target.value);
                }}
              />
              <p>Result found {concepts[0] ? "" + concepts.length : "0"}</p>
              <ul id="ul-all">
                {concepts.map((item, ind) => {
                  return (
                    <li
                      key={ind}
                      id={item.rxcui}
                      onClick={(ev) => {
                        displayTTY(ev, item.rxcui);
                      }}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </div>
            <button id="search-button" type="button">
              Search
            </button>
          </div>
          <div id="search-results" class="tty">
            <h3>Click on a concept.</h3>
            <div id="row-1" class="grid-container-1">
              <div id="bn" class="tty bn grid-area">
                <h3>Brand Name</h3>
                <ul id="ul-bn">
                  {bnConcepts.map((item, ind) => {
                    return (
                      <li
                        key={ind}
                        onClick={(ev) => {
                          displayTTY(ev, item.rxcui);
                        }}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div id="in" class="tty in grid-area">
                <h3>Ingredient</h3>
                <ul id="ul-in">
                  {inConcepts.map((item, ind) => {
                    return (
                      <li
                        key={ind}
                        onClick={(ev) => {
                          displayTTY(ev, item.rxcui);
                        }}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div id="min" class="tty min grid-area">
                <h3>Multiple Ingredients</h3>
                <ul id="ul-min">
                  {minConcepts.map((item, ind) => {
                    return <li key={ind}>{item.name}</li>;
                  })}
                </ul>
              </div>
            </div>
            <div id="row-2" class="grid-container-2">
              <div id="bpck" class="tty bpck grid-area">
                <h3>Brand Pack</h3>
                <ul id="ul-bpck">
                  {bpckConcepts.map((item, ind) => {
                    return (
                      <li
                        key={ind}
                        onClick={(ev) => {
                          displayTTY(ev, item.rxcui);
                        }}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div id="gpck" class="tty gpck grid-area">
                <h3>Generic Pack</h3>
                <ul id="ul-gpck">
                  {gpckConcepts.map((item, ind) => {
                    return (
                      <li
                        key={ind}
                        onClick={(ev) => {
                          displayTTY(ev, item.rxcui);
                        }}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div id="sbd" class="tty sbd grid-area">
                <h3>Semantic Branded Drug</h3>
                <ul id="ul-sbd">
                  {sbdConcepts.map((item, ind) => {
                    return (
                      <li
                        key={ind}
                        onClick={(ev) => {
                          displayTTY(ev, item.rxcui);
                        }}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div id="row-3" class="grid-container-3">
              <div id="scd" class="tty scd grid-area">
                <h3>Semantic Clinical Drug</h3>
                <ul id="ul-scd">
                  {scdConcepts.map((item, ind) => {
                    return (
                      <li
                        key={ind}
                        onClick={(ev) => {
                          displayTTY(ev, item.rxcui);
                        }}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div id="dfg" class="tty dfg grid-area">
                <h3>Dose Form Group</h3>
                <ul id="ul-dfg">
                  {dfgConcepts.map((item, ind) => {
                    return (
                      <li
                        key={ind}
                        onClick={(ev) => {
                          displayTTY(ev, item.rxcui);
                        }}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Home;