import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import pharmacyRxBaseUrl from "./APIs/BaseURL";

const EditHospital = () => {
  let navigate = useNavigate();
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWRtaW4iLCJhZG1pbl90eXBlIjowLCJpZCI6MiwiaWF0IjoxNjQzMjY1Mzg4fQ.DOWxeIL5HgsV5MRqwgSJV5KjqfkcBoeJeW-TM1kjDVI";
  const params = useParams();

  const [name, setName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [refillAPIURL, setRefillAPIURL] = useState("");
  const [refillAPIToken, setRefillAPIToken] = useState("");

  useEffect(() => {
    if (params.id) fetchHospital(params.id);
  }, []);

  const fetchHospital = async (id) => {
    console.log('Running "fetchHospital(' + id + ')"');
    let parameters = {
      id: +id,
    };

    try {
      await fetch(pharmacyRxBaseUrl + "/api/gethospital/" + id, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("fetchHospital(" + id + ")", data);
          if (data.name) setName(data.name);
          if (data.street_address) setStreetAddress(data.street_address);
          if (data.city) setCity(data.city);
          if (data.zip) setZip(data.zip);
          if (data.state) setState(data.state);
          if (data.refill_api_url) setRefillAPIURL(data.refill_api_url);
          if (data.refill_api_token) setRefillAPIToken(data.refill_api_token);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const editHospital = async () => {
    console.log('Running "editHospital()"');
    let data = {
      id: +params.id,
      name,
      street_address: streetAddress,
      city,
      zip,
      state,
      refillAPIToken,
      refillAPIURL,
    };
    console.log("data from editHospital() after instantiation: ", data);

    try {
      await fetch(pharmacyRxBaseUrl + "/api/updatehospital/" + params.id, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("data from editHospital() try block: ", data);
          if (data.status == "success") {
            alert(data.message);
            navigate("/hospitals");
          } else alert(data.message ? data.message : "Something went wrong");
        });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div className="container mt-3">
        <div className="card col-md-12">
          <div className="card-body">
            <h2>Edit Hospital</h2>
            <table>
              <tbody>
                <tr>
                  <td>
                    <label>Name</label> {/* NAME INPUT */}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      value={name}
                      onChange={(ev) => {
                        setName(ev.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Street Address</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Street address"
                      value={streetAddress}
                      onChange={(ev) => {
                        setStreetAddress(ev.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>City</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      value={city}
                      onChange={(ev) => {
                        setCity(ev.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Zip Code</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Zip code"
                      value={zip}
                      onChange={(ev) => {
                        setZip(ev.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>State</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="State"
                      value={state}
                      onChange={(ev) => {
                        setState(ev.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Refill API URL</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Refill API URL"
                      value={refillAPIURL}
                      onChange={(ev) => {
                        setRefillAPIURL(ev.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Refill API Token</label>
                  </td>
                  <td>
                    <textarea
                      className="form-control"
                      placeholder="Refill API Token"
                      value={refillAPIToken}
                      onChange={(ev) => {
                        setRefillAPIToken(ev.target.value);
                      }}
                      rows={4}
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <button className="btn btn-primary" onClick={editHospital}>
                      Update {/* ADD BUTTON */}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditHospital;
