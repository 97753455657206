import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import baseUrl from "./APIs/BaseURL";
import pharmacyToken from "./APIs/BaseURL";

const AddPharmacyManager = () => {
    let navigate = useNavigate();
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWRtaW4iLCJhZG1pbl90eXBlIjowLCJpZCI6MiwiaWF0IjoxNjQzMjY1Mzg4fQ.DOWxeIL5HgsV5MRqwgSJV5KjqfkcBoeJeW-TM1kjDVI";

    const [pharmacyId, setPharmacyId] = useState("");
    const [pharmacies, setPharmacies] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        fetchPharmacies();
    }, []);

    const fetchPharmacies = async () => {
        try {
            console.log("fetchPharmacyProfiles() TRY BLOCK");
            await fetch(baseUrl + "/api/getpharmacy_profiles", {
                method: "GET", 
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("fetchPharmacyProfiles() data: ", data);
                try {
                    console.log("setPharmacyProfiles(" + data + ") TRY block: ");
                    console.log("JSON.stringify.data: ", JSON.stringify(data));
                    setPharmacies(data);
                    console.log(" fetchPharmacyProfiles() pharmacies: ", pharmacies);
                } 
                catch (err) {
                    console.log("setPharmacyProfiles(" + data + ") CATCH block: ");
                    console.error(err)
                }
            })        
        } 
        catch (err) {
            console.log("fetchPharmacyProfiles() CATCH BLOCK");
            console.error(err);
        }
    };

    const addPharmacyManager = async (id) => {
        console.log('Running "addPharmacyManager()"');
        let data = {
            pharmacy_id: pharmacyId,
            first_name: firstName,
            last_name: lastName,
            phone,
            email
        }
        console.log("data from addPharmacyManager() after data instantiated:: ", data);

        try {
            await fetch(baseUrl + "/api/addmanager", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: pharmacyToken,
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("data from addPharmacyManager() try block: ", data);
                if (data.status == "success") {
                    alert(data.message);
                    navigate("/pharmacy");
                }
                else alert(data.message ? data.message : "Something went wrong");
            })
        } 
        catch (err) {
            console.error(err);
        }
    }

    return (
        <div className="container mt-3">
            <div className="card col-md-12">
                <div className="card-body">
                    <h2>Add Pharmacy Manager</h2>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <label>First Name</label> {/* FIRST NAME INPUT */}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="first name"
                                        value={firstName}
                                        onChange={(ev) => {
                                            setFirstName(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Last Name</label> {/* LAST NAME INPUT */}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="last name"
                                        value={lastName}
                                        onChange={(ev) => {
                                            setLastName(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Email Address</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email address"
                                        value={email}
                                        onChange={(ev) => {
                                            setEmail(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Phone Number</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone number"
                                        value={phone}
                                        onChange={(ev) => {
                                            setPhone(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Pharmacy</label>
                                </td>
                                <td>
                                {/* <div className="form-group col-lg-3 col-md-4 col-sm-12"> */}
                                    <select
                                        className="form-control"
                                        value={pharmacyId}
                                        onChange={(ev) => {
                                            setPharmacyId(ev.target.value);
                                        }}
                                    >
                                        <option value="">SELECT</option>
                                        {pharmacies.map((item, ind) => (
                                            <option key={ind} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                        {/* </div> */}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <button className="btn btn-primary" onClick={addPharmacyManager}>
                                        Submit {/* ADD BUTTON */}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default AddPharmacyManager;