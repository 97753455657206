import React, { useState, useEffect } from "react";
import baseUrl from "./APIs/BaseURL";
import pharmacyRxBaseUrl from "./APIs/BaseURL";

const Doctor = () => {
    
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWRtaW4iLCJhZG1pbl90eXBlIjowLCJpZCI6MiwiaWF0IjoxNjQzMjY1Mzg4fQ.DOWxeIL5HgsV5MRqwgSJV5KjqfkcBoeJeW-TM1kjDVI";
    
    const [doctors, setDoctors] = useState([]);
    const [hospitalDoctorId, setHospitalDoctorId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [gender, setGender] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [state, setState] = useState("");

    useEffect(() => {
        fetchDoctors();
    }, []);

    const fetchDoctors = async () => {

        try {
            await fetch(baseUrl + "/api/getdoctors", {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  token: token,
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("fetchDoctors() data in try block: ", data);
                setDoctors(data);
            });
        } 
        catch (err) {
            console.error(err);
        }
    }

    return (
        <div>
            <div className="row">
                <div className="card col-md-12">
                    <div className="card-body">
                        <h1>Doctors</h1>
                        <div className="row justify-content-center" id="search">
                                <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                    <input 
                                        className="form-control"
                                        type="text"
                                        placeholder="Search First Name" // SEARCH FIRST NAME INPUT
                                        value={firstName}
                                        onChange={(ev) => {
                                            setFirstName(ev.target.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                    <input 
                                        className="form-control"
                                        type="text"
                                        placeholder="Search Middle Name" // SEARCH MIDDLE NAME INPUT
                                        value={middleName}
                                        onChange={(ev) => {
                                            setMiddleName(ev.target.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                    <input 
                                        className="form-control"
                                        type="text"
                                        placeholder="Search Last Name" // SEARCH LAST NAME INPUT
                                        value={lastName}
                                        onChange={(ev) => {
                                            setLastName(ev.target.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                    <input 
                                        className="form-control"
                                        type="text"
                                        placeholder="Search Gender" // SEARCH GENDER INPUT
                                        value={gender}
                                        onChange={(ev) => {
                                            setGender(ev.target.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                    <input 
                                        className="form-control"
                                        type="text"
                                        placeholder="Search Street Address" // SEARCH STREET ADDRESS INPUT
                                        value={streetAddress}
                                        onChange={(ev) => {
                                            setStreetAddress(ev.target.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                    <input 
                                        className="form-control"
                                        type="text"
                                        placeholder="Search City" // SEARCH CITY INPUT
                                        value={city}
                                        onChange={(ev) => {
                                            setCity(ev.target.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                    <input 
                                        className="form-control"
                                        type="text"
                                        placeholder="Search Zip Code" // SEARCH ZIPCODE INPUT
                                        value={zip}
                                        onChange={(ev) => {
                                            setZip(ev.target.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                    <input 
                                        className="form-control"
                                        type="text"
                                        placeholder="Search State Code" // SEARCH STATE INPUT
                                        value={state}
                                        onChange={(ev) => {
                                            setState(ev.target.value);
                                        }}
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={fetchDoctors}
                                >
                                    Search {/* SEARCH BUTTON */}
                                </button>
                            </div>
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Middle Name</th>
                                    <th>Last Name</th>
                                    <th>Gender</th>
                                    <th>Street Address</th>
                                    <th>City</th>
                                    <th>Zip Code</th>
                                    <th>State</th>
                                </tr>
                            </thead>
                            <tbody>
                                { doctors.map((doctor, hospital_doctor_id) => (
                                    <tr key={hospital_doctor_id}>
                                        <td>{doctor.first_name}</td>
                                        <td>{doctor.middle_name}</td>
                                        <td>{doctor.last_name}</td>
                                        <td>{doctor.gender}</td>
                                        <td>{doctor.street_address}</td>
                                        <td>{doctor.city}</td>
                                        <td>{doctor.zip}</td>
                                        <td>{doctor.state}</td>
                                    </tr>
                                )) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Doctor;