import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// drugsRx
import Home from "./drugsRxComponents/Home";
import Loader from "./drugsRxComponents/Loader";

// pharmacyRx
import HeaderComponent from "./pharmacyRxComponents/HeaderComponent";
import LoginComponent from "./pharmacyRxComponents/LoginComponent";
import Dashboard from "./pharmacyRxComponents/Dashboard";
import Admin from "./pharmacyRxComponents/Admin";
import AddAdmin from "./pharmacyRxComponents/AddAdmin";
import EditAdmin from "./pharmacyRxComponents/EditAdmin";
// import Credentials from "./pharmacyRxComponents/Credentials";
// import AddCredentials from "./pharmacyRxComponents/AddCredentials";
// import EditCredentials from "./pharmacyRxComponents/EditCredentials";
import Hospital from "./pharmacyRxComponents/Hospital";
import AddHospital from "./pharmacyRxComponents/AddHospital";
import EditHospital from "./pharmacyRxComponents/EditHospital";
import Doctor from "./pharmacyRxComponents/Doctor";
import Patient from "./pharmacyRxComponents/Patient";
import Prescription from "./pharmacyRxComponents/Prescription";
import AddPrescription from "./pharmacyRxComponents/AddPrescription";
import Drug from "./pharmacyRxComponents/Drug";
import AddDrug from "./pharmacyRxComponents/AddDrug";
import EditDrug from "./pharmacyRxComponents/EditDrug";
import Disease from "./pharmacyRxComponents/Disease";
import PharmacyManager from "./pharmacyRxComponents/PharmacyManager";
import AddPharmacyManager from "./pharmacyRxComponents/AddPharmacyManager";
import InventoryAdmin from "./pharmacyRxComponents/InventoryAdmin";
import AddInventoryAdmin from "./pharmacyRxComponents/AddInventoryAdmin";
import PharmacyProfile from "./pharmacyRxComponents/PharmacyProfile";
import AddPharmacyProfile from "./pharmacyRxComponents/AddPharmacyProfile";
import EditPharmacyProfile from "./pharmacyRxComponents/EditPharmacyProfile";
// import UserComponent from "./components/UserComponent";

function App() {
  return (
    <div>
      <Router>
        <HeaderComponent />
        <div className="container">
          <Routes>
            {/* DrugsRx */}
            <Route path="/jajirx" element={<Home />}></Route>
            <Route path="/jajirx/loader" element={<Loader />}></Route>
            {/* PharmacyRx */}
            <Route path="/" element={<LoginComponent />}></Route>
            <Route path="/home" element={<Dashboard/>}></Route>
            <Route path="/administrators" element={<Admin />}></Route>
            <Route path="/add-admin" element={<AddAdmin />}></Route>
            <Route path="/edit-admin/:id" element={<EditAdmin />}></Route>
            {/* <Route path="/credentials" element={<Credentials />}></Route>
            <Route path="/add-credentials" element={<AddCredentials />}></Route>
            <Route path="/edit-credentials/:id" element={<EditCredentials />}></Route> */}
            <Route path="/hospitals" element={<Hospital />}></Route>
            <Route path="/add-hospital" element={<AddHospital />}></Route>
            <Route path="/edit-hospital/:id" element={<EditHospital />}></Route>
            <Route path="/doctors" element={<Doctor />}></Route>
            <Route path="/patients" element={<Patient />}></Route>
            <Route path="/prescriptions" element={<Prescription />}></Route>
            <Route path="/add-prescription" element={<AddPrescription />}></Route>
            <Route path="/drugs" element={<Drug />}></Route>
            <Route path="/add-drug" element={<AddDrug />}></Route>
            <Route path="/edit-drug/:id" element={<EditDrug/>}></Route>
            <Route path="/diseases" element={<Disease />}></Route>
            <Route path="/pharmacy" element={<PharmacyManager />}></Route>
            <Route path="/add-pharmacy" element={<AddPharmacyManager />}></Route>
            <Route path="/inventory" element={<InventoryAdmin />}></Route>
            <Route path="/add-inventory" element={<AddInventoryAdmin />}></Route>
            <Route path="/profile" element={<PharmacyProfile />}></Route>
            <Route path="/add-profile" element={<AddPharmacyProfile />}></Route>
            <Route path="/edit-profile/:id" element={<EditPharmacyProfile />}></Route>
            {/* <Route path="/user" element={<UserComponent />}></Route> */}
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;

// steps:
// make a new document
// import and route it Hear
// configure a clicky thing in the dropdown menu