
const AddPrescription = () => {

    return(
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <div className="card col-md-12">
                            <h3 className="text-center">Add Prescription</h3>
                            <div className="card-body">
                                <form>
                                    <h2>Patient</h2>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>First Name: </label>
                                            <input placeholder="First Name" name="firstName" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>Middle Name: </label>
                                            <input placeholder="Middle Name" name="middleName" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>Last Name: </label>
                                            <input placeholder="Last Name" name="lastName" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>Gender: </label>
                                            <input placeholder="Gender" name="gender" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>Street Address: </label>
                                            <input placeholder="Street Address" name="streetAddress" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>City: </label>
                                            <input placeholder="City" name="city" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>Zip Code: </label>
                                            <input placeholder="Zip Code" name="zipCode" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>State: </label>
                                            <input placeholder="State" name="state" className="form-control" />
                                        </div> 
                                    </div>
                                    <h2>Doctor</h2>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>First Name: </label>
                                            <input placeholder="First Name" name="firstName" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>Middle Name: </label>
                                            <input placeholder="Middle Name" name="middleName" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>Last Name: </label>
                                            <input placeholder="Last Name" name="lastName" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>Gender: </label>
                                            <input placeholder="Gender" name="gender" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>Street Address: </label>
                                            <input placeholder="Street Address" name="streetAddress" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>City: </label>
                                            <input placeholder="City" name="city" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>Zip Code: </label>
                                            <input placeholder="Zip Code" name="zipCode" className="form-control" />
                                        </div> 
                                    </div>
                                    <div className="form-group">
                                        <div className="flex-container">
                                            <label>State: </label>
                                            <input placeholder="State" name="state" className="form-control" />
                                        </div> 
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                    <div className="card col-md-12">
                        <h3 className="text-center">Add Prescription</h3>
                        <div className="card-body">
                            <form>
                                <h2>Drug</h2>
                                <div className="form-group">
                                    <div className="flex-container">
                                        <label>Drug Code: </label>
                                        <input placeholder="Drug Code" name="drugCode" className="form-control" />
                                    </div> 
                                </div>
                                <div className="form-group">
                                    <div className="flex-container">
                                        <label>Frequency: </label>
                                        <input placeholder="Frequency" name="frequency" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="flex-container">
                                        <label>Duration: </label>
                                        <input placeholder="Duration" name="duration" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="flex-container">
                                        <label>Refills: </label>
                                        <input placeholder="Refills" name="refills" className="form-control" />
                                    </div>
                                </div>
                                <h2>Pharmacy</h2>
                                <div className="form-group">
                                    <div className="flex-container">
                                        <label>Name: </label>
                                        <input placeholder="Name" name="name" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="flex-container">
                                        <label>Street Address: </label>
                                        <input placeholder="Street Address" name="streetAddress" className="form-control" />
                                    </div> 
                                </div>
                                <div className="form-group">
                                    <div className="flex-container">
                                        <label>City: </label>
                                        <input placeholder="City" name="city" className="form-control" />
                                    </div> 
                                </div>
                                <div className="form-group">
                                    <div className="flex-container">
                                        <label>Zip Code: </label>
                                        <input placeholder="Zip Code" name="zipCode" className="form-control" />
                                    </div> 
                                </div>
                                <div className="form-group">
                                    <div className="flex-container">
                                        <label>State: </label>
                                        <input placeholder="State" name="state" className="form-control" />
                                    </div> 
                                </div> 
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <h1>Add Prescription</h1>
        </div>
    );
}

export default AddPrescription;