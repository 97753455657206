import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import baseUrl from "./APIs/BaseURL";
import pharmacyRxBaseUrl from "./APIs/BaseURL";

const Hospital = () => {
  let navigate = useNavigate();
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWRtaW4iLCJhZG1pbl90eXBlIjowLCJpZCI6MiwiaWF0IjoxNjQzMjY1Mzg4fQ.DOWxeIL5HgsV5MRqwgSJV5KjqfkcBoeJeW-TM1kjDVI";
  const [hospitalsData, setHospitalsData] = useState([]);
  const [name, setName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");

  useEffect(() => {
    fetchHospitals();
  }, []);

  const fetchHospitals = async () => {
    console.log('Running "fetchHospitals()"');

    try {
      await fetch(baseUrl + "/api/gethospitals", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("fetchHospitals() data in try block: ", data);
          setHospitalsData(data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const deleteHospital = async (id) => {
    console.log('Running "deleteHospital(' + id + ')"');
    let data = {
      id: id,
    };
    console.log("deleteHospital() data: ", data);

    try {
      await fetch(pharmacyRxBaseUrl + "/api/deletehospital", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("data: ", data);
          if (data.status == "success") {
            alert(data.message);
            fetchHospitals();
          } else alert(data.message ? data.message : "Something went wrong");
        });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      {/* -- FILTER -- */}
      <h1 className="text-center">Hospital</h1>
      <div className="container">
        <div className="row justify-content-center" id="search">
          <div className="form-group col-lg-3 col-md-4 col-sm-12">
            <input
              className="form-control"
              type="text"
              placeholder="Search name" // SEARCH NAME INPUT
              value={name}
              onChange={(ev) => {
                setName(ev.target.value);
              }}
            />
          </div>
          <div className="form-group col-lg-3 col-md-4 col-sm-12">
            <input
              className="form-control"
              type="text"
              placeholder="Search by street address" // SEARCH STREET ADDRESS INPUT
              value={streetAddress}
              onChange={(ev) => {
                setStreetAddress(ev.target.value);
              }}
            />
          </div>
          <div className="form-group col-lg-3 col-md-4 col-sm-12">
            <input
              className="form-control"
              type="text"
              placeholder="Search by city" // SEARCH CITY INPUT
              value={city}
              onChange={(ev) => {
                setCity(ev.target.value);
              }}
            />
          </div>
          <div className="form-group col-lg-3 col-md-4 col-sm-12">
            <input
              className="form-control"
              type="text"
              placeholder="Search by zip code" // SEARCH ZIP INPUT
              value={zip}
              onChange={(ev) => {
                setZip(ev.target.value);
              }}
            />
          </div>
          <div className="form-group col-lg-3 col-md-4 col-sm-12">
            <input
              className="form-control"
              type="text"
              placeholder="Search by state" // SEARCH STATE INPUT
              value={state}
              onChange={(ev) => {
                setState(ev.target.value);
              }}
            />
          </div>

          <div className="form-group col-lg-3 col-md-4 col-sm-12">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={fetchHospitals}
            >
              Search {/* SEARCH BUTTON */}
            </button>
            &nbsp;
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={() => {
                navigate("/add-hospital");
              }}
            >
              Add {/* ADD BUTTON */}
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className="row">
          <div className="card col-md-12">
            <div className="card-body">
              <form>
                <h2>Hospital</h2>

                {/* Table */}
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Street Address</th>
                      <th>City</th>
                      <th>Zip Code</th>
                      <th>State</th>
                      <th>Personal Access Token</th>
                      <th>Refill API URL</th>
                      <th>Refill API Token</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hospitalsData.map((item, ind) => (
                      <tr key={ind}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.street_address}</td>
                        <td>{item.city}</td>
                        <td>{item.zip}</td>
                        <td>{item.state}</td>
                        <td>
                          <div
                            style={{
                              maxWidth: "200px",
                              maxHeight: "100px",
                              overflow: "auto",
                            }}
                          >
                            <p>{item.access_token}</p>
                          </div>
                        </td>
                        <td>
                          {item.refill_api_url ? item.refill_api_url : ""}
                        </td>
                        <td>
                          <div
                            style={{
                              maxWidth: "200px",
                              maxHeight: "100px",
                              overflow: "auto",
                            }}
                          >
                            <p>
                              {item.refill_api_token
                                ? item.refill_api_token
                                : ""}
                            </p>
                          </div>
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-info"
                            onClick={(ev) => {
                              navigate("/edit-hospital/" + item.id);
                            }}
                          >
                            Edit
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={(ev) => {
                              // ARE YOU SURE? if yes, deleteHospital(), else navigate(/admin)
                              deleteHospital(item.id);
                            }}
                          >
                            Delete {/* DELETE BUTTON */}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hospital;
