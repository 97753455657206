import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import baseUrl from "./APIs/BaseURL";
import pharmacyRxBaseUrl from "./APIs/BaseURL";

const Admin = () => {
  let navigate = useNavigate();
  let localToken = localStorage.getItem("token");
  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWRtaW4iLCJhZG1pbl90eXBlIjowLCJpZCI6MiwiaWF0IjoxNjQzMjY1Mzg4fQ.DOWxeIL5HgsV5MRqwgSJV5KjqfkcBoeJeW-TM1kjDVI";
  const [adminData, setAdminData] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [permissions, setPermissions] = useState("");
  const [superAdmin, setIsSuperAdmin] = useState("");
  // const baseUrl = "http://107.155.116.113";
  // const baseUrl = "https://emrapi.jajirx.com";

  useEffect(() => {
    console.log(token);

    // fetch permissions "/api/getpermissions"


    // if (rxnorm_ui: admin_users is_superuser == true) {
      // look into edit a dude to see how to get a specific is_superuser of someone.

      // call an endpoint that returns the object 
      // search the database
      // SELECT * FROM admin_users WHERE email = 'apermissions@gmail.com';
      // 

    // } 
    // else {
      
    // }
    console.log("localStorage.email: ", localStorage.email);
    console.log("localStorage: ", localStorage);
    fetchAdmins();
    // console.log("localStorage.email: ", localStorage.email);
  }, []);

   // sends the data object and token and retrieves admins JSON 
   const fetchAdmins = async () => {
    console.log('Running "fetchAdmins()"');

    try {
      await fetch(baseUrl + "/api/adminUsers", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("localStorage.email: ", localStorage.email);
          console.log("/api/getadmins data : ", data);
          setAdminData(data);
        });
    } catch (err) {
      console.error(err);
    }
  };

    // This method should have an ARE YOU SURE? message pop up that you have to confirm 
    const deleteAdmin = async (id) => {
        console.log('Running "deleteAdmin(' + id + ')"');
        let data = {
          id: id,
        };
        console.log("deleteAdmin() data: ", data);
    
        try {
          // await fetch(baseUrl + "/api/deleteAdminUser", {
          await fetch(baseUrl + `/api/deleteAdminUser/${id}`, {
            method: "DELETE",
            body: JSON.stringify(data),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              token: token,
            },
          })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              console.log("Admin.jsx /deleteAdminUser data: ", data);
              if (data === true) {
                alert("Admin was deleted successfully.");
                fetchAdmins();
              } 
              else {
                console.log("else block data: ", data);
                alert("Something went wrong");
              }
            });
        } catch (err) {
          console.error(err);
        }
      };

    return (
        <div>
      {/* -- FILTER -- */}
      <h1 className="text-center">Administrators</h1>
      <div className="container">
        <div className="row justify-content-center" id="search">
          <div className="form-group col-lg-3 col-md-4 col-sm-12">
            <input 
              className="form-control"
              type="text"
              placeholder="Search name" // SEARCH NAME INPUT
              value={name}
              onChange={(ev) => {
                setName(ev.target.value);
              }}
            />
          </div>
          <div className="form-group col-lg-3 col-md-4 col-sm-12">
            <input
              className="form-control"
              type="text"
              placeholder="Search email" // SEARCH EMAIL INPUT
              value={email}
              onChange={(ev) => {
                setEmail(ev.target.value);
              }}
            />
          </div>
          {/* <div className="form-group col-lg-3 col-md-4 col-sm-12">
            <select
              className="form-control"
              value={adminType}
              onChange={(ev) => {
                setAdminType(ev.target.value);
              }}
            >
              <option value="">Search Admin Type</option> {/* ADMIN TYPE SELECT */}
              {/* <option value="0">Admin</option>
              <option value="1">Hospital</option>
              <option value="2">External Pharmacy</option>
            </select>
          </div> */}

          <div className="form-group col-lg-3 col-md-4 col-sm-12">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={fetchAdmins}
            >
              Search {/* SEARCH BUTTON */}
            </button>
            &nbsp;
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={() => {
                navigate("/add-admin");
              }}
            >
              Add {/* ADD BUTTON */}
            </button>
          </div>
        </div>
      </div>
      {/* -- TABLE -- */}
      <div>
        <div className="row">
          <div className="card col-md-12">
            <div className="card-body">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Permissions</th>
                    <th>Superadmin</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {adminData.map((item, ind) => (
                    <tr key={ind}>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>
                        <div
                          style={{
                            maxWidth: "250px",
                            maxHeight: "100px",
                            overflow: "auto",
                          }}
                        >
                          {item.permissions}
                        </div>
                      </td>
                      {/* <td>
                        {
                          ["Admin", "Hospital", "External Pharmacy"][
                            item.admin_type
                          ]
                        }
                      </td> */}
                      <td>{item.is_superuser.toString()}</td>
                      {/* <td>
                        <div
                          style={{
                            maxWidth: "200px",
                            maxHeight: "100px",
                            overflow: "auto",
                          }}
                        >
                          <p>*permissions*</p>
                        </div>
                      </td> */}
                      <td>
                        <button
                          className="btn btn-sm btn-info"
                          onClick={(ev) => {
                            console.log("item: ", item);
                            console.log("item.id: ", item.id);
                            navigate("/edit-admin/" + item.id);
                          }}
                        >
                          Edit {/* EDIT BUTTON */}
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={(ev) => {
                            // ARE YOU SURE? if yes, deleteAdmin(), else navigate(/admin)
                            deleteAdmin(item.id);
                          }}
                        >
                          Delete {/* DELETE BUTTON */}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
}

export default Admin;