import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import baseUrl from "./APIs/BaseURL";
import pharmacyToken from "./APIs/BaseURL";

const EditPharmacyProfile = () => {
    const params = useParams();
    let navigate = useNavigate();

    const [name, setName] = useState("");
    const [regnNo, setRegnNo] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState(""); // Phone
    const [address1, setAddress1] = useState(""); // address_1
    const [address2, setAddress2] = useState(""); // address_2
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [ownerEmail, setOwnerEmail] = useState("");
    const [ownerContact, setOwnerContact] = useState("");

    useEffect(() => {
        if(params.id) fetchPharmacyProfile(params.id);
        // *** No API to fetch a single Pharmacy profile
    }, [])

    const fetchPharmacyProfile = async (id) => {
        console.log("Running \"fetchPharmacyProfile(" + id + ")\"");
        let parameters = {
            id: +id,
        };

        try {
            await fetch(baseUrl + `/api/getpharmacy_profile/${id}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: pharmacyToken,
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("fetchPharmacyProfile(" + id + ") data:", data);
                if(data.name) setName(data.name);
                if(data.regn_no) setRegnNo(data.regn_no);
                if(data.email) setEmail(data.email);
                if(data.phone) setPhone(data.phone);
                if(data.address_1) setAddress1(data.address_1);
                if(data.address_2) setAddress2(data.address_2);
                if(data.city) setCity(data.city);
                if(data.state) setState(data.state);
                if(data.zip) setZip(data.zip);
                if(data.owner_name) setOwnerContact(data.owner_name);
                if(data.owner_email) setOwnerEmail(data.owner_email);
                if(data.owner_contact) setOwnerContact(data.owner_contact);
            });
        } 
        catch (err) {
            console.error(err);
        }
    };

    const editPharmacyProfile = async () => {
        // console.log("editPharmacyProfile() id: ", id);
        let data = {
            id: +params.id,
            name,
            regn_no: regnNo,
            email,
            phone,
            address_1: address1,
            address_2: address2,
            city,
            state,
            zip,
            owner_name: ownerName,
            owner_email: ownerEmail,
            owner_contact: ownerContact
        };

        try {
            console.log("data.id: ", data.id);
            await fetch(baseUrl + `/api/updatepharmacy_profile/${data.id}`, {
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: pharmacyToken
                }
            })
            .then((response) => {
                console.log("response: ", response);
                console.log("response.json(): ", response.json());
                return response.json();
            })
            .then((data) => {
                console.log("data from editPharmacyProfile() try block: ", data);
                if (data.status == "success") {
                    alert(data.message);
                    navigate("/profile");
                }
                else alert(data.message ? data.message : "Something went wrong");
            })
        } 
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="container mt-3">
            <div className="card col-md-12">
                <div className="card-body">
                    <h2>Edit Pharmacy Profile</h2>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <label>Name</label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="name"
                                        value={name}
                                        onChange={(ev) => {
                                            setName(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Registration No.</label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="registration no."
                                        value={regnNo}
                                        onChange={(ev) => {
                                            setRegnNo(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Email Address</label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="email"
                                        value={email}
                                        onChange={(ev) => {
                                            setEmail(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Phone No.</label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="phone"
                                        value={phone}
                                        onChange={(ev) => {
                                            setPhone(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Address Line 1</label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="address ln. 1"
                                        value={address1}
                                        onChange={(ev) => {
                                            setAddress1(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Address Line 2</label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="address ln. 2"
                                        value={address2}
                                        onChange={(ev) => {
                                            setAddress2(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>City</label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="city"
                                        value={city}
                                        onChange={(ev) => {
                                            setCity(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>State</label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="state"
                                        value={state}
                                        onChange={(ev) => {
                                            setState(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Zip Code</label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="zip code"
                                        value={zip}
                                        onChange={(ev) => {
                                            setZip(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Owner Name</label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="o. name"
                                        value={ownerName}
                                        onChange={(ev) => {
                                            setOwnerName(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Owner Email</label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="o. email"
                                        value={ownerEmail}
                                        onChange={(ev) => {
                                            setOwnerEmail(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Owner Contact</label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="o. contact"
                                        value={ownerContact}
                                        onChange={(ev) => {
                                            setOwnerContact(ev.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button 
                                        className="btn btn-primary"
                                        onClick={editPharmacyProfile}
                                    >
                                        Submit
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default EditPharmacyProfile;