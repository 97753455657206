import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import baseUrl from "./APIs/BaseURL";
import pharmacyRxBaseUrl from "./APIs/BaseURL";

const Drug = () => {
    // let navigate = useNavigate();
    let navigate = useNavigate();
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWRtaW4iLCJhZG1pbl90eXBlIjowLCJpZCI6MiwiaWF0IjoxNjQzMjY1Mzg4fQ.DOWxeIL5HgsV5MRqwgSJV5KjqfkcBoeJeW-TM1kjDVI";
    
    const [drugs, setDrugs] = useState([]);
    const [drugCode, setDrugCode] = useState("");
    const [drugName, setDrugName] = useState("");
    const [semanticBrandName, setSemanticBrandName] = useState("");

    useEffect(() => {
        // if(!token) navigate("/");
        fetchDrugs();
    }, []);

    const fetchDrugs = async () => {

        try {
            await fetch(baseUrl + "/api/getdrugs", {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  token: token,
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("fetchDrugs() data in try block: ", data);
                setDrugs(data);
            });
        } 
        catch (err) {
            console.error(err);
        }        
    }

    const deleteDrug = async (id) => {
        console.log('Running "deleteDrug(' + id + ')"');
        let data = {
          id: id,
        };
        console.log("deleteDrug() data: ", data);
    
        try {
            await fetch(pharmacyRxBaseUrl + "/api/deletedrug", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("data: ", data);
                if (data.status == "success") {
                    alert(data.message);
                    fetchDrugs();
                } 
                else alert(data.message ? data.message : "Something went wrong");
            });
        } 
        catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            <div className="row">
                <div className="card col-md-12">
                    <div className="card-body">
                        <h1>Drugs</h1>
                        <div className="container">
                            <div className="row justify-content-center" id="search">
                                <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                    <input 
                                        className="form-control"
                                        type="text"
                                        placeholder="Search Drug Code" // SEARCH DRUG CODE INPUT
                                        value={drugCode}
                                        onChange={(ev) => {
                                            setDrugCode(ev.target.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                    <input 
                                        className="form-control"
                                        type="text"
                                        placeholder="Search Drug Name" // SEARCH DRUG NAME INPUT
                                        value={drugName}
                                        onChange={(ev) => {
                                            setDrugName(ev.target.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                    <input 
                                        className="form-control"
                                        type="text"
                                        placeholder="Semantic Brand Name" // SEARCH SEMANTIC BRAND NAME INPUT
                                        value={semanticBrandName}
                                        onChange={(ev) => {
                                            setSemanticBrandName(ev.target.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-lg-3 col-md-4 col-sm-12">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={fetchDrugs}
                                    >
                                        Search {/* SEARCH BUTTON */}
                                    </button>
                                    &nbsp;
                                    <button
                                        type="submit"
                                        className="btn btn-secondary"
                                        // onClick={() => {
                                        //     // navigate("/add-drug");
                                        // }}
                                        onClick={() => {
                                            navigate("/add-drug");
                                        }}
                                    >
                                        Add {/* ADD BUTTON */}
                                    </button>
                                </div>
                                <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Drug Code</th>
                                            <th>Drug Name</th>
                                            <th>Semantic Brand Name</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { drugs.map((drug, id) => (
                                            <tr key={id}>
                                                <td>{drug.drug_code}</td>
                                                <td>{drug.drug_name}</td>
                                                <td>{drug.semantic_brand_name}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-sm btn-info"
                                                        onClick={() => {
                                                            navigate("/edit-drug/" + drug.id);
                                                        }}
                                                    >
                                                        Edit
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        className="btn btn-sm btn-danger"
                                                        onClick={(ev) => {
                                                            // ARE YOU SURE? if yes, deleteHospital(), else navigate(/admin)
                                                            deleteDrug(drug.id);
                                                        }}
                                                    >
                                                        Delete {/* DELETE BUTTON */}
                                                    </button>
                                                </td>
                                            </tr>
                                        )) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Drug;