import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import baseUrl from "./APIs/BaseURL";
import pharmacyToken from "./APIs/BaseURL";

const PharmacyManager = () => {
    let navigate = useNavigate();
    
    const [pharmacyManagers, setPharmacyManagers] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        fetchPharmacyManagers();
    
    }, []);

    const fetchPharmacyManagers = async () => {
        console.log('Running "fetchPharmacyManagers()"');

        try {
            await fetch(baseUrl + "/api/getmanagers", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("fetchPharmacyManagers() data in try block: ", data);
                setPharmacyManagers(data);
            });
        } 
        catch (err) {
            console.error(err);
        }
    };

    const deletePharmacyManager = async (id) => {
        console.log('Running "deletePharmacyManager(' + id + ')"');
        let data = {
            id: id,
        };
        console.log("deletePharmacyManager() data: ", data);

        try {
            await fetch(baseUrl + `/api/deletemanager/${id}`, {
                method: "DELETE",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: pharmacyToken,
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("data: ", data);
                if (data.status == "success") {
                    alert(data.message);
                    fetchPharmacyManagers();
                }
                else alert(data.message ? data.message : "Something went wrong");
            })
        } 
        catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            <h1 className="text-center">Pharmacy Managers</h1>
            <div>
                <button
                    type="submit"
                    className="btn btn-secondary"
                    onClick={() => {
                        navigate("/add-pharmacy");
                    }}
                >
                    Add Pharmacy Manager {/* ADD BUTTON */}
                </button>
            </div>
            <div className="row">
                <div className="card col-md-12">
                    <div className="card-body">
                        <form>
                            {/* Table */}
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Phone Number</th>
                                        <th>Email Address</th>
                                        <th>Pharmacy</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { pharmacyManagers.map((item, ind) => (
                                        <tr key={ind}>
                                            <td>{item.first_name}</td>
                                            <td>{item.last_name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.email}</td>
                                            <td>{item.pharmacy_profile?.name}</td>
                                            <td>
                                                <button
                                                    className="btn btn-sm btn-info"
                                                    onClick={(ev) => {
                                                        // navigate("/edit-inventory/" + item.id);
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-sm btn-danger"
                                                    onClick={(ev) => {
                                                        deletePharmacyManager(item.id);
                                                    }}
                                                >
                                                    Delete {/* DELETE BUTTON */}
                                                </button>
                                            </td>
                                        </tr>
                                    )) }
                                </tbody>
                            </table>         
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PharmacyManager;