import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import baseUrl from "./APIs/BaseURL";

const PharmacyProfile = () => {
    let navigate = useNavigate();

    const [pharmacyProfiles, setPharmacyProfiles] = useState([]);
    const [name, setName] = useState("");
    const [regnNo, setRegnNo] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState(""); // Phone
    const [address1, setAddress1] = useState(""); // address_1
    const [address2, setAddress2] = useState(""); // address_2
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [ownerEmail, setOwnerEmail] = useState("");
    const [ownerContact, setOwnerContact] = useState("");

    useEffect(() => {
        fetchPharmacyProfiles();
        console.log("pharmacyProfiles: ", pharmacyProfiles);
    }, [])

    const fetchPharmacyProfiles = async () => {
        console.log("running fetchPharmacyProfiles() (pre try block)");

        try {
            console.log("fetchPharmacyProfiles() TRY BLOCK");
            await fetch(baseUrl + "/api/getpharmacy_profiles", {
                method: "GET", 
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("fetchPharmacyProfiles() data: ", data);
                try {
                    console.log("setPharmacyProfiles(" + data + ") TRY block: ");
                    console.log("JSON.stringify.data: ", JSON.stringify(data));
                    setPharmacyProfiles(data);
                    console.log(" fetchPharmacyProfiles() pharmacyProfiles: ", pharmacyProfiles);
                } 
                catch (err) {
                    console.log("setPharmacyProfiles(" + data + ") CATCH block: ");
                    console.error(err)
                }
            })        
        } 
        catch (err) {
            console.log("fetchPharmacyProfiles() CATCH BLOCK");
            console.error(err);
        }
    }

    const deletePharmacyProfiles = async (id) => {
        let data = {
            id: id
        }

        try {
            // await
        } 
        catch (err) {
            console.error(err);
        }
    }

    return (
        <div>
            <h1 className="text-center">Pharmacy Profile</h1>
            <div>
                <button
                    type="submit"
                    className="btn btn-secondary"
                    onClick={() => {
                        navigate("/add-profile");
                    }}
                >
                    Add Pharmacy Profile {/* ADD BUTTON */}
                </button>
            </div>
            <div className="row">
                <div className="card col-md-12">
                    <div className="card-body">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Registration No.</th>
                                    <th>Email Address</th>
                                    <th>Phone Number</th>
                                    <th>Address Line 1</th>
                                    <th>Address Line 2</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Zip Code</th>
                                    <th>Owner Name</th>
                                    <th>Owner Email</th>
                                    <th>Owner Contact</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                { pharmacyProfiles.map((item, ind) => (
                                    <tr key={ind}>
                                        <td>{item.name}</td>
                                        <td>{item.regn_no}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone}</td>
                                        <td>{item.address_1}</td>
                                        <td>{item.address_2}</td>
                                        <td>{item.city}</td>
                                        <td>{item.state}</td>
                                        <td>{item.zip}</td>
                                        <td>{item.owner_name}</td>
                                        <td>{item.owner_email}</td>
                                        <td>{item.contact}</td>
                                        <td>
                                            <button
                                                className="btn btn-primary"
                                                onClick={(ev) => {
                                                    navigate(`/edit-profile/${item.id}`);
                                                }}
                                            >
                                                Edit {/* EDIT BUTTON */}
                                            </button>
                                            &nbsp;
                                            <button
                                                className="btn btn-sm btn-info"
                                            >
                                                Delete {/* DELETE BUTTON */}
                                            </button>
                                        </td>
                                    </tr>
                                )) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PharmacyProfile;