
const Dashboard = () => {

    return (
        <div>
            <h1>DASHBOARD</h1>
            {/* <p>Welcome, {localStorage.email}</p>
            <br />
            <ul>
                <li><div><a href="https://jajirx.com/">JajiRx</a></div></li>
                <li><div><a href="https://ascend.ascendehr.com/landing-page">Ascend</a></div></li>
            </ul> */}
        </div>
    );
}

export default Dashboard;