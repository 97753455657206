import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import baseUrl from "./APIs/BaseURL";

// import './App.css';




// if (data[0].is_superuser === false) {
//     console.log("Not. A superuser. Access to ");
//     navigate("/home");
//   }



const LoginComponent = () => {
    let navigate = useNavigate();
    // const baseUrl = "https://emrapi.jajirx.com";
    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWRtaW4iLCJhZG1pbl90eXBlIjowLCJpZCI6MiwiaWF0IjoxNjQzMjY1Mzg4fQ.DOWxeIL5HgsV5MRqwgSJV5KjqfkcBoeJeW-TM1kjDVI";

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleClick = () => {
        console.log(email);
        fetch(baseUrl + "/api/login", {
            method: "POST",
            body: JSON.stringify({
                email: email,
                password: password,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
        .then((response) => {
            console.log("Response: CONFIRMED -->", response);
            return response.json();
        })
        .then((result) => {
            console.log("response.json (result) #2 -->", result);
            if (result.status) {
                if (result.status == "error") {
                    setErrorMessage("Invalid. Something went wrong.");
                    alert(result.message);
                } 
                else {
                    localStorage.setItem("email", result.email);
                    localStorage.setItem("token", result.token);
                    navigate("/home"); // before it goes here, it needs to get the new permissions
                    // if home page, run fetchPermissions
                }   
            } 
            else {
                setErrorMessage("Something went wrong");
            }
        })
    }

    return(
        <div>
            
            <div className="login">
                {errorMessage ? <h3>{errorMessage}</h3> : ""}

                <h3>Login Here</h3>

                <label htmlFor="username">Username</label>
                <input 
                    type="text" 
                    placeholder="Email or Phone" 
                    id="username" 
                    value={email}
                    onChange={(ev) => {
                        setEmail(ev.target.value);
                    }}
                />

                <label htmlFor="password">Password</label>
                <input 
                    type="password" 
                    placeholder="Password" 
                    id="password" 
                    value={password}
                    onChange={(ev) => {
                        setPassword(ev.target.value)
                    }}
                />

                <button onClick={handleClick}>Log In</button>
            </div>         
        </div>
    );
}

export default LoginComponent;